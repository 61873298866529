import environment from '@/environment'

export default {
  // Endpoints
  loginEndpoint: `${environment.uri}users/signin`,
  registerEndpoint: '',
  refreshEndpoint: '',
  logoutEndpoint: '',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
